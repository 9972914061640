import React, { useState, useEffect } from 'react'
import './Typewriter.css'

const Typewriter = ({
  messages,
  typingSpeed = 150,
  deletionSpeed = 100,
  writingPause = 500,
  displayPause = 2000,
}: {
  messages: string[]
  typingSpeed?: number
  deletionSpeed?: number
  writingPause?: number
  displayPause?: number
}) => {
  const [index, setIndex] = useState(0)
  const [subIndex, setSubIndex] = useState(0)
  const [backward, setBackward] = useState(false)
  const [blink, setBlink] = useState(true)

  useEffect(() => {
    if (subIndex === messages[index].length + 1 && !backward) {
      setTimeout(() => setBackward(true), displayPause) // pause when word is fully displayed
    } else if (subIndex === 0 && backward) {
      setBackward(false)
      setIndex((prevIndex) => (prevIndex + 1) % messages.length)
      setTimeout(() => {}, writingPause) // pause before writing starts
    }

    const timeout = setTimeout(
      () => {
        setSubIndex((prevSubIndex) => prevSubIndex + (backward ? -1 : 1))
      },
      backward ? deletionSpeed : typingSpeed,
    ) // separate typing and deletion speeds

    return () => clearTimeout(timeout)
  }, [subIndex, index, backward])

  useEffect(() => {
    const timeout2 = setTimeout(() => {
      setBlink((prev) => !prev)
    }, 500)
    return () => clearTimeout(timeout2)
  }, [blink])

  return (
    <>
      <h2 className="typewriter-text">
        {`${messages[index].substring(0, subIndex)}`}
      </h2>
    </>
  )
}

export default Typewriter
