import React from 'react'
import { FunctionBuilder } from './FunctionBuilder'

export const SingleAbiFn = ({
  fnAbi,
  index,
}: {
  fnAbi: any
  index: number
}) => {
  return (
    <>
      <a
        data-bs-toggle="collapse"
        href={`#single-abi-fn-collapse-${index}`}
        role="button"
        aria-expanded="false"
        aria-controls={`single-abi-fn-collapse-${index}`}
        className="text-black"
        // data-target={`#single-abi-fn-collapse-${index}`}
        onClick={() => console.log(fnAbi)}
      >
        {fnAbi.name}
      </a>
      <div className="collapse p-2 m-2" id={`single-abi-fn-collapse-${index}`}>
        <div className="card card-body">
          <FunctionBuilder abiFn={fnAbi} />
        </div>
      </div>
    </>
  )
}
