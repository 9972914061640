import React from 'react'
import Typewriter from '../../components/Typewriter'
import './index.css'
import { ScrollIcon } from '../../components/ScrollIcon'
import ScrollFadeComponent from '../../components/ScrollFade'

export const ComingSoon = () => {
  const messages = [
    'NFT services',
    'Gaming Projects',
    'DeFi protocols',
    'Staking mechanisms',
    'Crypto ecosystems',
    'dApps',
    'NFT Marketplaces',
    'Decentralized Exchanges',
    'Launchpads',
    'Decentralized Apps',
    'Lotteries',
    'Payment protocols',
    'DEXes',
    'Aggregators',
    'Token bridges',
    'Vesting contracts',
    'Infrastructures',
  ]

  const shuffleArray = (array: string[]) => {
    let currentIndex = array.length,
      temporaryValue,
      randomIndex

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex)
      currentIndex -= 1

      // And swap it with the current element.
      temporaryValue = array[currentIndex]
      array[currentIndex] = array[randomIndex]
      array[randomIndex] = temporaryValue
    }

    return array
  }

  return (
    <div className="coming-soon">
      <div className="container">
        <div className="row">
          <div className="col-12 d-flex justify-content-center align-items-center">
            <div className="hero-wrapper">
              <div className="hero">
                <h1 style={{ fontSize: '76px' }}>erdWorks</h1>
                <h3>
                  the MultiversX end-to-end product development services
                  provider
                </h3>
                <div className="building-message-container">
                  <h2 className="me-2">
                    <strong>Building.. </strong>
                  </h2>
                  <Typewriter
                    typingSpeed={60}
                    deletionSpeed={20}
                    writingPause={300}
                    displayPause={1000}
                    messages={shuffleArray(messages)}
                  />
                </div>
                <div className="scroll-container">
                  <ScrollFadeComponent>
                    <ScrollIcon />
                  </ScrollFadeComponent>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
