import React from 'react'
import logo from './logo.svg'
import './App.css'
import { Layout } from './components/Layout'
import { Services } from './pages/Services'
import { Portfolio } from './pages/Portfolio'
import { ContactUs } from './pages/ContactUs'
import { ComingSoon } from './pages/ComingSoon'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { Tools } from './pages/Tools'
function App() {
  const home = (
    <>
      <ComingSoon />
      <Portfolio />
      <ContactUs />
    </>
  )

  const router = createBrowserRouter([
    { path: '/', element: home },
    { path: '/tools', element: <Tools /> },
  ])
  return (
    <div className="App">
      <RouterProvider router={router} />
      {/* <Layout>
        <Services />
        <Portfolio />
        <ContactUs />
      </Layout> */}
    </div>
  )
}

export default App
