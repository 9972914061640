import React, { useState } from 'react'
import { ToolRenderer } from './ToolRenderer'
import { GenerateInteractions } from './ToolImpl'
import { ToolType, ToolsCfg } from './toolsCfg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUp } from '@fortawesome/free-solid-svg-icons'
import './style.css'

export const Tools = () => {
  const toolsMapping = []

  const [currentTool, setCurrentTool] = useState<ToolType | undefined>()
  return (
    <div className="container">
      <div className="row">
        <div className="col-12 text-center mt-4 mb-3">
          <h1>Tools</h1>
        </div>
        {currentTool === undefined &&
          ToolsCfg.map((tm, idx) => (
            <div className="col-3" key={`tools-selector-menu-${idx}`}>
              <div className="card">
                <div className="card-header">{tm.name}</div>
                <div className="card-footer">
                  <button
                    className="btn btn-black"
                    onClick={() => setCurrentTool(tm.type)}
                  >
                    Select
                  </button>
                </div>
              </div>
            </div>
          ))}
        {currentTool !== undefined && (
          <ToolRenderer
            type={currentTool}
            onNavigateBack={() => setCurrentTool(undefined)}
          />
        )}
      </div>
      <div className="move-to-top">
        <a className="btn btn-black" href="#">
          <FontAwesomeIcon icon={faArrowUp} />
        </a>
      </div>
    </div>
  )
}
