export const buildInputParams = (
  inputs: {
    name: string
    type: string
  }[],
): {
  params: string[]
  typedParams: string[]
} => {
  const params: string[] = []
  const typedParams: string[] = []

  for (let i = 0; i < inputs.length; i++) {
    params.push(inputs[i].name)
    typedParams.push(`${inputs[i].name}: ${TYPE_MAPPING[inputs[i].type]}`)
  }

  return {
    params,
    typedParams,
  }
}

const TYPE_MAPPING: { [key: string]: string } = {
  u64: 'number',
  BigUint: 'BigNumber',
  Address: 'Address',
  TokenIdentifier: 'string',
  EgldOrEsdtTokenIdentifier: 'string',
}
