import { useState } from 'react'

export const AbiInput = ({
  onProceed,
}: {
  onProceed: (abi: string) => void
}) => {
  const [abiJsonArea, setAbiJsonArea] = useState('')
  return (
    <>
      <div className="col-12 mb-3">
        From .abi.json to TS interactions. Input your .abi.json content and
        start budling!
      </div>
      <div className="col-12">
        <textarea
          rows={25}
          cols={100}
          style={{ borderRadius: '.625rem' }}
          onChange={(e) => setAbiJsonArea(e.target.value)}
        />
      </div>
      <div className="col-12">
        <button
          className="btn btn-black"
          onClick={() => onProceed(abiJsonArea)}
        >
          Submit
        </button>
      </div>
    </>
  )
}
