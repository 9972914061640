import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { ToolType, ToolsCfg } from './toolsCfg'
import { GenerateInteractions } from './ToolImpl'

export const ToolRenderer = ({
  type,
  onNavigateBack,
}: {
  type: ToolType | undefined
  onNavigateBack: () => void
}) => {
  const [title, setTitle] = useState('')

  useEffect(() => {
    if (type === undefined) {
      setTitle('')
    } else {
      setTitle(ToolsCfg.find((t) => t.type === type)?.name ?? '')
    }
  })

  const toolRenderer = () => {
    switch (type) {
      case ToolType.InteractionsGenerator:
        return <GenerateInteractions />
    }
  }
  return (
    <div className="card">
      <div className="card-header d-flex justify-content-between">
        <div></div>
        <div>{title}</div>
        <button className="btn btn-black" onClick={onNavigateBack}>
          <FontAwesomeIcon icon={faArrowLeft} />
        </button>
      </div>
      <div className="card-body">{toolRenderer()}</div>
    </div>
  )
}
