import React from 'react'
import { QueryFn } from './QueryFn'
import { TxFn } from './TxFn'

export const FunctionBuilder = ({ abiFn }: { abiFn: any }) => {
  if (abiFn.mutability === 'readonly') {
    return <QueryFn abiFn={abiFn} />
  } else {
    return <TxFn />
  }
}

