import React, { useEffect } from 'react'
import { buildInputParams } from './logic'

export const QueryFn = ({ abiFn }: { abiFn: any }) => {
  const getQueryCode = () => {
    let { params, typedParams } = buildInputParams(abiFn.inputs)
    let code = QUERY_TEMPLATE.replaceAll('FUNC_NAME', abiFn.name)
      .replaceAll('FUNC_PARAMS_TYPED', typedParams.join(', '))
      .replaceAll('FUNC_PARAMS', params.join(', '))
    return code
  }

  useEffect(() => {}, [])
  return <code className="text-start">{getQueryCode()}</code>
}

const QUERY_TEMPLATE =
  '//FUNC_NAME.ts\n \
   \n \
const FUNC_NAME = async (FUNC_PARAMS_TYPED) => { \n \
  const contract = await getSmartContract(); \n \
  const interaction = contract.methods.FUNC_NAME([FUNC_PARAMS]); \n \
  const query = interaction.check().buildQuery(); \n \
  const response = await Provider.queryContract(query); \n \
  const endpointDef = interaction.getEndpoint(); \n \
  const parsedResponse = Parser.parseQueryResponse(response, endpointDef); \n \
  \n \
  if (parsedResponse.returnCode.isSuccess()) { \n \
    return parsedResponse.firstValue?.valueOf(); \n \
  } \n \
  return null; \n \
};'
