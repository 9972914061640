import React, { useState } from 'react'
import { AbiInput } from './abiInput'
import { AbiFnSelector } from './abiFnSelector'

export const GenerateInteractions = () => {
  const [abiJsonArea, setAbiJsonArea] = useState<string>('')
  return (
    <div className="container">
      {abiJsonArea === '' && <AbiInput onProceed={setAbiJsonArea} />}
      {abiJsonArea !== '' && <AbiFnSelector abiFileContent={abiJsonArea} />}
      {abiJsonArea !== '' && (
        <div className="col-12">
          <button className="btn btn-black" onClick={() => setAbiJsonArea('')}>
            Start over
          </button>
        </div>
      )}
    </div>
  )
}
