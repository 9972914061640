import React from 'react'
import './style.css'

export const ScrollIcon = () => {
  return (
    <span className="scroll-icon">
      <span className="scroll-icon__wheel-outer">
        <span className="scroll-icon__wheel-inner"></span>
      </span>
    </span>
  )
}
