import React, { useState, useEffect } from 'react'
import './ScrollFadeComponent.css'

function ScrollFadeComponent({ children }: { children: React.ReactNode }) {
  const [opacity, setOpacity] = useState(1)

  const checkVisibility = () => {
    const scrolled = document.documentElement.scrollTop
    if (scrolled > 200) {
      setOpacity(0)
    } else {
      setOpacity(1 - scrolled / 200)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', checkVisibility)
    return () => window.removeEventListener('scroll', checkVisibility)
  }, [])

  return (
    <div className="content" style={{ opacity: opacity }}>
      {children}
    </div>
  )
}

export default ScrollFadeComponent
