import { GenerateInteractions } from './ToolImpl'

export enum ToolType {
  InteractionsGenerator,
}

export const ToolsCfg = [
  {
    name: 'Contract interaction generator',
    component: GenerateInteractions,
    type: ToolType.InteractionsGenerator,
  },
]
