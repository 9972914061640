import { useEffect, useState } from 'react'
import { SingleAbiFn } from './singleAbiFn'

export const AbiFnSelector = ({
  abiFileContent,
}: {
  abiFileContent: string
}) => {
  const [functions, setFunctions] = useState([])
  useEffect(() => {
    const abiObj = JSON.parse(abiFileContent)
    setFunctions(abiObj.endpoints)
  }, [abiFileContent])
  return (
    <div className="container">
      <div className="row">
        <div className="col-12 mb-3">
          The generated code relies on a few predefined functions.
          <br />
          <a
            data-bs-toggle="collapse"
            href={`#preset-code-box-collapse-`}
            role="button"
            aria-expanded="false"
            aria-controls={`preset-code-box-collapse-`}
            className="text-black"
          >
            Click here to show them
          </a>
          <div className="collapse p-2 m-2" id={`preset-code-box-collapse-`}>
            <div className="card card-body text-start">
              <code>{DEFAULT_FN}</code>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-4">
          <h2>Identified functions</h2>
        </div>
        <div className="col-8">
          {functions.map((f, i) => (
            <div key={`parsed-abi-functions-${i}`}>
              {<SingleAbiFn fnAbi={f} index={i} />}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

const DEFAULT_FN =
  "//common.ts \n \
import { ProxyNetworkProvider } from '@multiversx/sdk-network-providers'; \n \
import { \n \
  ResultsParser, \n \
  AbiRegistry, \n \
  SmartContractAbi, \n \
  SmartContract, \n \
  Address \n \
} from '@multiversx/sdk-core/out'; \n \
import myAbiJson from './myContract.abi.json;'\n \
\n \
\n \
\n \
/* replace with your gateway provider url */\n \
export const Provider = new ProxyNetworkProvider('https://gateway.multiversx.com', { \n \
  timeout: 10000 \n \
}); \n \
\n \
\n \
export const Parser = new ResultsParser(); \n \
\n \
/* replace with your smart contract's address */\n \
const scAddress = 'erd1...';\n \
export const getSmartContract = () => { \n \
  const json = abiMapping(scAddress); \n \
  const abiRegistry = AbiRegistry.create(myAbiJson); \n \
  const abi = new SmartContractAbi(abiRegistry); \n \
  return new SmartContract({ \n \
    address: new Address(scAddress), \n \
    abi \n \
  }); \n \
};"
