import React from 'react'
import './style.css'

export const ContactUs = () => {
  return (
    <div id="contact-us" className="contact-us-wrapper">
      <div className="container">
        <div className="row">
          <div className="col-12 text-center mb-5">
            <h1>Want to join us?</h1>
            <h6>Fill the form and we'll write you as soon as possible!</h6>
          </div>
          {/* <div className="col-12">
            <a
              href="https://0pmwgppd1nl.typeform.com/to/arKP03H6"
              className="btn btn-black w-25 p-2 mt-3"
            >
              Yes
            </a>
          </div>
          <div className="col-12">
            <a
              href="https://0pmwgppd1nl.typeform.com/to/arKP03H6"
              className="btn btn-black w-50 p-2 mt-3 btn-lg"
            >
              Also yes, but larger
            </a>
          </div> */}
          <div className="col-12">
            <a
              href="https://0pmwgppd1nl.typeform.com/to/arKP03H6"
              className="btn btn-black w-75 p-2 mt-3 btn-lg"
            >
              Yes
            </a>
          </div>
          <div className="col-12">
            <a
              href="https://0pmwgppd1nl.typeform.com/to/arKP03H6"
              className="btn btn-black w-50 p-2 mt-3"
            >
              Yes, but I'm shy
            </a>
          </div>
        </div>
      </div>
      {/* <div className="email-link-container">
        Or write us at{' '}
        <a href="mailto:contact@erd-works.com">contact@erd-works.com</a>
      </div> */}
      <div className="email-link-container">
        Don't like forms? Write us at{' '}
        <a href="mailto:contact@erd-works.com">contact@erd-works.com</a>
      </div>
    </div>
  )
}
