import React from 'react'
import './style.css'

export const Portfolio = () => {
  const logos = [
    {
      img: 'https://app.ageofzalmoxis.com/images/aoz/logoaoz.png',
      link: 'https://ageofzalmoxis.com',
    },
    {
      img:
        'https://zionmultiverse.com/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flogo_dark.82b86bc1.png&w=128&q=75',
      link: 'https://zionmultiverse.com/',
    },
    {
      img:
        'https://vegld.vestax.finance/static/media/vesta_snake.7970bc4c8e24eb9a729f.png',
      link: 'https://vestax.finance',
    },
    // {
    //   img: 'https://maiarpunks.store/wp-content/uploads/cropped-logobanner.png',
    //   link: 'https://maiarpunks.store/',
    // },
    {
      img:
        'https://static.wixstatic.com/media/316bc1_a595bfbbc39c4b91bea1b8aa9319fe2a~mv2.png/v1/fill/w_290,h_92,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Logo%20White-01.png',
      link: 'https://refracto.io',
    },
  ]

  return (
    <div className="portfolio-wrapper dark" id="Portfolio">
      <h1 className="mb-5">
        <strong>Together with..</strong>
      </h1>
      <div className="container">
        <div className="row rounded p-5">
          {logos.map((l, i) => (
            <div className="col" key={`happy-customer-${i}`}>
              <a href={l.link} target="_blank" rel="noreferrer">
                <img
                  src={l.img}
                  style={{
                    width: 'auto',
                    height: 'auto',
                    maxHeight: '150px',
                    maxWidth: '280px',
                  }}
                />
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
